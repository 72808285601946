import React, { useState, useEffect } from "react";
import { updateConfigs, getConfigs } from '../../AadhanApi';
import { Switch, message } from 'antd';
import './config.scss';
import { useSelector } from "react-redux";
import { Button, Form, Input ,Row,Col} from 'antd';
const AppConfig = () => {
  const [form] = Form.useForm();
  const [configFor, setConfigFor] = useState(null);
  const accessToken = useSelector((state) => state.app.accessToken);

  // Fetch configuration data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getConfigs(accessToken);
        if (response.data && response.data.config) {
          const configData = response.data.config
          const config_for = response.data.config_for
           // Access the config object
          
          setConfigFor(config_for);
          // Populate form fields with fetched config data
          form.setFieldsValue({
            android_latest_version: configData.android_latest_version,
            android_min_version: configData.android_min_version,
            latest_version_in_settings: configData.latest_version_in_settings,
            ad_pub_id: configData.ad_pub_id,
            native_ad_id: configData.native_ad_id,
            google_ad_position: configData.google_ad_position,
            html_position: configData.html_position,
            google_ads_enabled: configData.google_ads_enabled,
            show_refer_earn: configData.show_refer_earn,
            show_comments: configData.show_comments,
            lang_experiment:configData.lang_experiment,
            reporter_update:configData.reporter_update
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch configuration data");
      }
    };

    fetchData();
  }, [accessToken, form]);

  const handleSubmit = async (values) => {
    try {
      const requestData = {
        android_latest_version: values.android_latest_version,
        android_min_version: values.android_min_version,
        latest_version_in_settings: values.latest_version_in_settings,
        ad_pub_id: values.ad_pub_id,
        native_ad_id: values.native_ad_id,
        google_ad_position: values.google_ad_position,
        html_position: values.html_position,
        google_ads_enabled: values.google_ads_enabled,
        show_refer_earn: values.show_refer_earn,
        show_comments: values.show_comments,
        lang_experiment:values.lang_experiment,
        reporter_update:values.reporter_update
        
      };


      await updateConfigs(accessToken, requestData, configFor);
      message.success("Configuration updated successfully");
    } catch (error) {
      console.error("Error updating config:", error);
      message.error("Failed to update configuration");
    }
  };

  return (
    <div className="left" >
      <h1 className="heading">App Configuration</h1>
      <div className="top">
      <Form
        form={form }
        name="config-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
        layout="horizontal"
        
      >
         <Row gutter={16}>
         <Col span={12}>
         
        <Form.Item
          name="android_latest_version"
          label="Android Latest Version"
          rules={[{ required: false, message: "Please enter latest version" }]}>
          <Input className="ant-input" />
        </Form.Item>

        <Form.Item
          name="android_min_version"
          label="Android Min Version"
          rules={[{ required: false, message: "Please enter minimum version" }]}>
          <Input className="max-w-xs" />
        </Form.Item>

        <Form.Item
          name="latest_version_in_settings"
          label="Latest Version in Settings"
          rules={[{ required: false, message: "Please enter settings version" }]}>
          <Input className="max-w-xs" />
        </Form.Item>
       
        <Form.Item
          name="ad_pub_id"
          label="Ad Publish ID"
          rules={[{ required: false, message: "Please enter ad publish ID" }]}>
          <Input className="max-w-xs" />
        </Form.Item>

        <Form.Item
          name="native_ad_id"
          label="Native Ad ID"
          rules={[{ required: false, message: "Please enter ad ID" }]}>
          <Input className="max-w-xs" />
        </Form.Item>

        <Form.Item
          name="google_ad_position"
          label="Google Ad Position"
          rules={[{ required: false, message: "Please enter ad position" }]}>
          <Input className="max-w-xs" />
        </Form.Item>

        </Col>
        <Col span={12}>
       
        
        <Form.Item  style={{marginLeft:"15px"}}
          name="html_position"
          label="HTML Position"
          rules={[{ required: false, message: "Please enter HTML position" }]}>
          <Input className="max-w-xs" />
        </Form.Item>
        <Form.Item  style={{marginLeft:"30px"}}
          name="lang_experiment"
          label="Lang Experiment"
          rules={[{ required: false, message: "Please enter lang experiment" }]}>
          <Input className="max-w-xs" />
        </Form.Item>
   
        <Form.Item style={{marginLeft:"50px"}}
          name="google_ads_enabled"
          label="Enable Google Ads"
          valuePropName="checked">
          <Switch />
        </Form.Item>
 
        <Form.Item  style={{marginLeft:"50px"}}
          name="show_refer_earn"
          label="Show Refer & Earn"
          valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item  style={{marginLeft:"30px"}}
          name="show_comments"
          label="Show Comments"
          valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item style={{marginLeft:"30px"}}
          name="reporter_update"
          label="Reporter Update"
          valuePropName="checked">
          <Switch />
        </Form.Item>
        <Col span={7} >

       
        </Col>
</Col>
</Row>
<Row>
<Form.Item style={{margin:"1rem auto"}} wrapperCol={{ offset: 8, span: 16 }}>
  <div style={{display:"flex", justifyContent:"center", marginRight:"185px"}}>
          <Button type="primary" htmlType="submit" >
            Update Configuration
          </Button>
          </div>
        </Form.Item>
</Row>
      </Form>
      </div>
      
    </div>
  );
};

export default AppConfig;
