import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Spin } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { convertImageUrl, createSnippet } from '../../../AadhanApi'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'

function ShortNewsToSnippetsFunc({
    record,
    onMoveSuccess,
    onMoveError,
    language,
    categories,
    categoriesLoading,
}) {
    const token = useSelector((state) => state.app.accessToken)

    const getLanguageId = (languageString) => {
        const languageMap = {
            telugu: 2,
            hindi: 3,
            tamil: 4,
            english: 1,
        }
        return languageMap[languageString.toLowerCase()]
    }

    const selectedLanguage = getLanguageId(language)
    const [snippetCreationLoading, setSnippetCreationLoading] = useState(false)

    const transformShortNewsToSnippet = async (
        shortNewsRecord,
        selectedCategoryId,
        selectedCategoryName
    ) => {
        const employeeId = localStorage.getItem('EMPLOYEE_ID')
        const employeeName = localStorage.getItem('EMPLOYEE_NAME')

        let convertedImageUrl = shortNewsRecord.image_url

        if (shortNewsRecord.image_url) {
            try {
                const response = await convertImageUrl(
                    shortNewsRecord.image_url,
                    language,
                    token
                )
                convertedImageUrl = response
            } catch (error) {
                console.error('Failed to convert image URL:', error)
            }
        }

        return {
            content_id: shortNewsRecord.content_id,
            snippet_category_name: selectedCategoryName,
            content_status: 'approved',
            content_type: 'shortnews',
            created_type: '',
            description: shortNewsRecord.description,
            image_url: convertedImageUrl,
            image_url_16_9: '',
            image_url_3_4: '',
            keywords: '',
            language_id: selectedLanguage,
            left_color: '#000000',
            right_color: '#000000',
            mp4_video_url: shortNewsRecord.stream_video_url || '',
            thumbnailimage_url: '',
            title: shortNewsRecord.title,
            uploaded_by: parseInt(employeeId) || 0,
            uploaded_by_name: employeeName || 'string',
            uploaded_date: moment().toISOString(),
            yt_video_url: shortNewsRecord.yt_video_url || '',
            snippet_category_id: selectedCategoryId,
            code: '',
        }
    }

    const handleMoveToSnippets = async (categoryId) => {
        setSnippetCreationLoading(true)

        try {
            const selectedCategory = categories.find(
                (cat) => cat.snippet_category_id === categoryId
            )

            if (!selectedCategory) {
                throw new Error('Category not found')
            }

            const snippetData = await transformShortNewsToSnippet(
                record,
                selectedCategory.snippet_category_id,
                selectedCategory.snippet_category_name
            )

            const keywordsResponse = await generateKeyWord(
                snippetData.title,
                snippetData.description,
                snippetData.language_id
            )
            snippetData.keywords = keywordsResponse?.keywords || ''

            const response = await createSnippet(snippetData, token)

            if (response.data.success) {
                onMoveSuccess()
            }
        } catch (error) {
            console.error('Error moving to snippets:', error)
            onMoveError()
        } finally {
            setSnippetCreationLoading(false)
        }
    }

    const LoadingOverlay = ({ message }) => (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Spin size="large" />
                <p style={{ marginTop: '10px' }}>{message}</p>
            </div>
        </div>
    )

    useEffect(() => {
        const preventRefresh = (e) => {
            if (snippetCreationLoading) {
                e.preventDefault()
                e.returnValue = ''
            }
        }

        window.addEventListener('beforeunload', preventRefresh)

        return () => {
            window.removeEventListener('beforeunload', preventRefresh)
        }
    }, [snippetCreationLoading])

    return (
        <>
            {snippetCreationLoading && (
                <LoadingOverlay message="Moving to Snippets..." />
            )}

            <Dropdown
                disabled={
                    categoriesLoading ||
                    categories.length === 0 ||
                    snippetCreationLoading
                }
                overlay={
                    <Menu onClick={({ key }) => handleMoveToSnippets(key)}>
                        {categories.map((category) => (
                            <Menu.Item key={category.snippet_category_id}>
                                {snippetCreationLoading
                                    ? 'Creating...'
                                    : `Move to ${category.snippet_category_name} Snippets`}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <ExportOutlined
                    style={{
                        paddingLeft: '5px',
                        color: snippetCreationLoading ? 'gray' : 'inherit',
                    }}
                />
            </Dropdown>
        </>
    )
}

export default ShortNewsToSnippetsFunc
