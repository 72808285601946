import React, { useState, useEffect } from 'react'
import { message, Spin } from 'antd'
import { useSelector } from 'react-redux'
import './HoroscopeMain.scss'
import TextArea from 'antd/es/input/TextArea'
import HoroscopeDropdowns from './HoroscopeDropdowns'
import {
    getHoroscopeCards,
    createHoroscopeCard,
    updateHoroscopeCard,
    getAstrologyMasterData,
    uploadImageToTest,
    getPanchangamCard,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import { monthsData } from './LanguageSpecificMonths.js'
import { dayNamesData } from './LanguageSpecificDays.js'
import CoverPage from '../../assets/horoscopeCardsTemplates/First_Page.jpg'
import Aquarius from '../../assets/horoscopeCardsTemplates/Aquarius.jpg'
import Aries from '../../assets/horoscopeCardsTemplates/Aries.jpg'
import Cancer from '../../assets/horoscopeCardsTemplates/Cancer.jpg'
import Capricorn from '../../assets/horoscopeCardsTemplates/Capricorn.jpg'
import Gemini from '../../assets/horoscopeCardsTemplates/Gemini.jpg'
import Leo from '../../assets/horoscopeCardsTemplates/Leo.jpg'
import Libra from '../../assets/horoscopeCardsTemplates/Libra.jpg'
import Pisces from '../../assets/horoscopeCardsTemplates/Pisces.jpg'
import Sagittarius from '../../assets/horoscopeCardsTemplates/Sagittarius.jpg'
import Scorpio from '../../assets/horoscopeCardsTemplates/Scorpio.jpg'
import Taurus from '../../assets/horoscopeCardsTemplates/Taurus.jpg'
import Virgo from '../../assets/horoscopeCardsTemplates/Virgo.jpg'
import { useLocation } from 'react-router'

const HoroscopeMain = () => {
    const location = useLocation()
    const { gridLanguage } = location.state || {}
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [language, setLanguage] = useState(
        gridLanguage || languageData?.[0]?.language_id
    )
    const [month, setMonth] = useState(
        () =>
            localStorage.getItem('selectedMonth') ||
            // gridMonth ||
            new Date().getMonth().toString()
    )
    const [day, setDay] = useState(
        () =>
            localStorage.getItem('selectedDay') ||
            // gridDay ||
            new Date().getDate().toString()
    )
    const [hasData, setHasData] = useState(false)
    const TAMIL_LANGUAGE_ID = 4

    const [horoscopeTexts, setHoroscopeTexts] = useState({})
    const [horoscopeUrls, setHoroscopeUrls] = useState({})
    const [horoscopeIds, setHoroscopeIds] = useState({})
    const [uploadedDates, setUploadedDates] = useState({})
    const [zodiacData, setZodiacData] = useState({})
    const [isGridButtonDisabled, setIsGridButtonDisabled] = useState(false)
    const [isSaveDirectlyDisabled, setIsSaveDirectlyDisabled] = useState(false)
    const [isOverlayVisible, setIsOverlayVisible] = useState(false)
    const [overlayMessage, setOverlayMessage] = useState('')
    const [hasPanchangam, setHasPanchangam] = useState(false)

    const horoscopes = [
        'Aries',
        'Taurus',
        'Gemini',
        'Cancer',
        'Leo',
        'Virgo',
        'Libra',
        'Scorpio',
        'Sagittarius',
        'Capricorn',
        'Aquarius',
        'Pisces',
    ]
    const horoscopeImages = {
        default: {
            CoverPage: CoverPage,
            Aries,
            Taurus,
            Gemini,
            Cancer,
            Leo,
            Virgo,
            Libra,
            Scorpio,
            Sagittarius,
            Capricorn,
            Aquarius,
            Pisces,
        },
    }

    useEffect(() => {
        setHasPanchangam(false)
        fetchHoroscopes()
    }, [language, month, day])

    useEffect(() => {
        const preventRefresh = (e) => {
            if (isOverlayVisible) {
                e.preventDefault()
                e.returnValue = ''
            }
        }

        window.addEventListener('beforeunload', preventRefresh)

        return () => {
            window.removeEventListener('beforeunload', preventRefresh)
        }
    }, [isOverlayVisible])

    const translateMonth = (englishMonth, selectedLanguage) => {
        const languageKey =
            {
                1: 'english',
                2: 'telugu',
                3: 'hindi',
                4: 'tamil',
            }[selectedLanguage] || 'english'

        const monthIndex = monthsData.english.indexOf(englishMonth)
        return monthsData[languageKey][monthIndex]
    }

    const getDayName = (year, month, day, language) => {
        const date = new Date(year, monthsData.english.indexOf(month), day)
        const dayIndex = date.getDay()
        const languageKey =
            {
                1: 'english',
                2: 'telugu',
                3: 'hindi',
                4: 'tamil',
            }[language] || 'english'

        return dayNamesData[languageKey][dayIndex]
    }

    const currentYear = new Date().getFullYear()
    const formattedDate = dayjs()
        .year(currentYear)
        .month(parseInt(month))
        .date(parseInt(day))
        .format('YYYY-MM-DD')
    const cardDate = formattedDate + 'T00:00:00'

    const handleLanguageChange = (value) => {
        setLanguage(value)
        localStorage.setItem('selectedLanguage', value)
    }

    const handleMonthChange = (value) => {
        setMonth(value)
        localStorage.setItem('selectedMonth', value)
    }

    const handleDayChange = (value) => {
        setDay(value)
        localStorage.setItem('selectedDay', value)
    }

    const LoadingOverlay = ({ message }) => (
        <div className="loading-overlay">
            <div className="loading-content">
                <Spin size="large" />
                <p>{message}</p>
            </div>
        </div>
    )

    const getZodiacData = async (accessToken, params) => {
        try {
            const dates = await getAstrologyMasterData(accessToken, params)
            setZodiacData(dates.data.data.data)
        } catch (error) {
            console.log('error is ::::', error)
        }
    }

    const getLocalizedHoroscopeName = (englishName) => {
        if (!zodiacData || Object.keys(zodiacData).length === 0) {
            return englishName
        }

        const zodiac = Object.values(zodiacData).find(
            (z) => z[1].trim() === englishName.trim()
        )
        return zodiac ? zodiac[language] : englishName
    }

    const getEnglishHoroscopeName = (localizedName) => {
        if (!zodiacData || Object.keys(zodiacData).length === 0) {
            return localizedName
        }
        const zodiac = Object.values(zodiacData).find(
            (z) => z[language] === localizedName
        )
        return zodiac ? zodiac[1] : localizedName
    }

    const fetchHoroscopes = async () => {
        getZodiacData(accessToken, 'zodiacs')
        const currentYear = new Date().getFullYear()
        const formattedDate = dayjs()
            .year(currentYear)
            .month(parseInt(month))
            .date(parseInt(day))
            .format('YYYY-MM-DD')

        try {
            const panchangamResponse = await getPanchangamCard(
                accessToken,
                language,
                formattedDate
            )
            setHasPanchangam(panchangamResponse.data.success)

            try {
                const response = await getHoroscopeCards(
                    accessToken,
                    language,
                    formattedDate
                )

                if (response.data.success) {
                    const fetchedHoroscopes = response.data.horoscopes || []
                    const allHoroscopesHaveData = horoscopes.every((sign) =>
                        fetchedHoroscopes.some(
                            (h) =>
                                h.zodiac_sign.name ===
                                getEnglishHoroscopeName(sign)
                        )
                    )
                    setIsGridButtonDisabled(!allHoroscopesHaveData)
                    setIsSaveDirectlyDisabled(allHoroscopesHaveData)
                    setHasData(fetchedHoroscopes.length > 0)

                    const newHoroscopeTexts = {}
                    const newHoroscopeSummaries = {}
                    const newHoroscopeUrls = {}
                    const newHoroscopeIds = {}
                    const newUploadedDates = {}

                    horoscopes.forEach((sign) => {
                        const englishSign = getEnglishHoroscopeName(sign)
                        const horoscope = fetchedHoroscopes.find(
                            (h) => h.zodiac_sign.name === englishSign
                        )

                        if (horoscope) {
                            newHoroscopeTexts[sign] =
                                horoscope.zodiac_sign.short_desc
                            newHoroscopeSummaries[sign] =
                                horoscope.zodiac_sign.short_desc
                            newHoroscopeUrls[sign] = horoscope.zodiac_sign.url
                            newHoroscopeIds[sign] = horoscope.code
                            newUploadedDates[sign] = horoscope.uploaded_date
                        } else {
                            newHoroscopeTexts[sign] = ''
                            newHoroscopeSummaries[sign] = ''
                            newHoroscopeUrls[sign] = ''
                            newHoroscopeIds[sign] = ''
                            newUploadedDates[sign] = ''
                        }
                    })

                    setHoroscopeTexts(newHoroscopeTexts)
                    setHoroscopeUrls(newHoroscopeUrls)
                    setHoroscopeIds(newHoroscopeIds)
                    setUploadedDates(newUploadedDates)
                } else {
                    setHoroscopeTexts({})
                    setHoroscopeUrls({})
                    setHoroscopeIds({})
                    setUploadedDates({})
                    setHasData(false)
                    setIsSaveDirectlyDisabled(false)
                }
            } catch (error) {
                console.error('Failed to fetch horoscopes:', error)
                setHoroscopeTexts({})
                setHoroscopeUrls({})
                setHoroscopeIds({})
                setUploadedDates({})
                setHasData(false)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const preloadFonts = () => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = 1
            canvas.height = 1

            ctx.font = '12px Mallanna'
            ctx.fillText('అనుకున్న పనులన్నీ పూర్తవుతాయి..', 0, 0)
            ctx.font = '12px Sree Krushnadevaraya'
            ctx.fillText('అనుకున్న పనులన్నీ పూర్తవుతాయి..', 0, 0)
            ctx.font = '12px Meera Inimai'
            ctx.fillText('தமிழ் எழுத்துரு சோதனை', 0, 0)
            ctx.font = '12px Mangal'
            ctx.fillText('हिंदी फ़ॉन्ट परीक्षण..', 0, 0)
            ctx.font = '12px AdobeDevanagari'
            ctx.fillText('हिंदी फ़ॉन्ट परीक्षण..', 0, 0)
            ctx.font = '12px AdobeDevanagariBold'
            ctx.fillText('हिंदी फ़ॉन्ट परीक्षण..', 0, 0)
            ctx.font = `60px Gurajada`
            ctx.fillText('అనుకున్న పనులన్నీ పూర్తవుతాయి..', 0, 0)
            ctx.font = `60px Mandali`
            ctx.fillText('అనుకున్న పనులన్నీ పూర్తవుతాయి..', 0, 0)

            setTimeout(resolve, 50)
        })
    }

    const loadFonts = async () => {
        const fonts = [
            { name: 'Mallanna', weight: 'normal' },
            { name: 'Sree Krushnadevaraya', weight: 'bold' },
            { name: 'Meera Inimai', weight: 'normal' },
            { name: 'AdobeDevanagari', weight: 'normal' },
            { name: 'AdobeDevanagariBold', weight: 'bold' },
            { name: 'Mandali', weight: 'bold' },
        ]

        try {
            await Promise.all(
                fonts.map((font) =>
                    document.fonts.load(`${font.weight} 12px ${font.name}`)
                )
            )
            return true
        } catch (error) {
            console.error('Font loading failed:', error)
            return false
        }
    }

    const handleSaveDirectly = async () => {
        if (!hasPanchangam) {
            message.error('Please create Panchangam card first')
            return
        }

        const emptyHoroscopes = horoscopes.filter(
            (sign) => !horoscopeTexts[getEnglishHoroscopeName(sign)]
        )
        if (emptyHoroscopes.length > 0) {
            if (emptyHoroscopes.length > 5) {
                message.error('Please fill text for all horoscopes')
            } else {
                message.error(
                    `Please fill the text for ${emptyHoroscopes
                        .map(getLocalizedHoroscopeName)
                        .join(', ')}`
                )
            }
            return
        }
        setIsOverlayVisible(true)
        setOverlayMessage('Preparing fonts...')

        try {
            await preloadFonts()
            let successfulSaves = 0

            for (let i = 0; i < horoscopes.length; i++) {
                setOverlayMessage(
                    `Saving ${getLocalizedHoroscopeName(horoscopes[i])}...`
                )
                try {
                    await saveHoroscopeDirectly(horoscopes[i])
                    successfulSaves++
                } catch (error) {
                    console.error(`Error saving ${horoscopes[i]}:`, error)
                }
                await fetchHoroscopes()
            }

            if (successfulSaves === horoscopes.length) {
                message.success('All horoscopes have been saved')
            } else {
                message.success(
                    `${successfulSaves} out of ${horoscopes.length} horoscopes have been saved`
                )
            }
        } catch (error) {
            console.error('Error during direct save:', error)
            message.error('An error occurred during saving. Please try again.')
        } finally {
            setIsOverlayVisible(false)
            setHasData(true)
        }
    }

    const saveHoroscopeDirectly = async (horoscope) => {
        try {
            const originalText = horoscopeTexts[horoscope]

            const tempCanvas = document.createElement('canvas')
            const tempContext = tempCanvas.getContext('2d')

            tempCanvas.width = 590
            tempCanvas.height = 1280

            const date = dayjs(cardDate)
            const monthName = date.format('MMMM')
            const dayName = date.format('DD')

            await drawHoroscopeOnCanvas(
                tempContext,
                horoscope,
                originalText,
                monthName,
                dayName
            )

            const blob = await new Promise((resolve) =>
                tempCanvas.toBlob(resolve, 'image/jpeg', 0.95)
            )

            const imageFile = new File([blob], `${horoscope}.jpeg`, {
                type: 'image/jpeg',
            })
            const imageUploadResponse = await uploadImageToTest(
                imageFile,
                accessToken
            )
            const imageUrl = imageUploadResponse.data

            const payload = {
                language_id: language,
                status: 'complete',
                date: cardDate,
                zodiac_sign: {
                    name: horoscope,
                    full_desc: originalText,
                    short_desc: originalText,
                    url: imageUrl,
                },
            }

            if (horoscopeIds[horoscope]) {
                await updateHoroscopeCard(
                    horoscopeIds[horoscope],
                    payload,
                    accessToken
                )
            } else {
                const response = await createHoroscopeCard(payload, accessToken)
                setHoroscopeIds((prev) => ({
                    ...prev,
                    [horoscope]: response.data.code,
                }))
            }

            setHoroscopeUrls((prev) => ({ ...prev, [horoscope]: imageUrl }))
        } catch (error) {
            console.error(`Failed to save ${horoscope}:`, error)
            message.error(`Failed to save ${horoscope}. Please try again.`)
        }
    }

    const wrapAndTruncateText = (
        context,
        text,
        maxWidth,
        maxLines,
        lineHeight
    ) => {
        const words = text.split(' ')
        let lines = []
        let currentLine = ''

        for (const word of words) {
            const testLine = currentLine ? `${currentLine} ${word}` : word
            const metrics = context.measureText(testLine)
            if (metrics.width > maxWidth && currentLine !== '') {
                lines.push(currentLine)
                currentLine = word
            } else {
                currentLine = testLine
            }
        }
        lines.push(currentLine)

        if (lines.length > maxLines) {
            lines = lines.slice(0, maxLines - 1)
            lines.push('... (text truncated)')
        }

        return lines
    }

    const drawHoroscopeOnCanvas = async (
        context,
        horoscope,
        text,
        month,
        day
    ) => {
        await loadFonts()
        const canvas = context.canvas
        const width = canvas.width
        const height = canvas.height

        const isTamil = language === TAMIL_LANGUAGE_ID
        const backgroundImage = await loadImage(
            horoscopeImages.default[horoscope]
        )
        context.drawImage(backgroundImage, 0, 0, width, height)

        const localizedName = getLocalizedHoroscopeName(horoscope)
        let nameFontSize, nameY

        if (isTamil) {
            nameFontSize = width * 0.09
            nameY = height * 0.44
            context.font = `bold ${nameFontSize}px Meera Inimai`
        } else if (language === 3) {
            nameFontSize = width * 0.12
            nameY = height * 0.44
            context.font = `bold ${nameFontSize}px AdobeDevanagariBold`
        } else if (language === 2) {
            nameFontSize = width * 0.08
            nameY = height * 0.438
            context.font = `bold ${nameFontSize}px Mandali`
        } else {
            nameFontSize = width * 0.1
            nameY = height * 0.438
            context.font = `bold ${nameFontSize}px Gurajada`
        }

        context.fillStyle = 'rgba(247,171,75,255)'
        context.textAlign = 'center'
        context.fillText(localizedName, width * 0.5, nameY)

        let fontSize, lineHeight, maxWidth

        if (isTamil) {
            fontSize = width * 0.052
            lineHeight = width * 0.077
            context.font = `${fontSize}px Meera Inimai`
            maxWidth = width * 0.835
        } else if (language === 3) {
            fontSize = width * 0.0548
            lineHeight = width * 0.09
            context.font = `${fontSize}px Mangal`
            maxWidth = width * 0.79
        } else if (language === 2) {
            fontSize = width * 0.0548
            lineHeight = width * 0.07
            context.font = `${fontSize}px Mallanna`
            maxWidth = width * 0.79
        } else {
            fontSize = width * 0.0548
            lineHeight = width * 0.075
            context.font = `${fontSize}px Mallanna`
            maxWidth = width * 0.79
        }

        context.fillStyle = 'white'
        context.textAlign = 'center'

        // const maxWidth = width * 0.79
        const maxLines = 12
        const lines = wrapAndTruncateText(
            context,
            text,
            maxWidth,
            maxLines,
            lineHeight
        )
        lines.forEach((line, index) => {
            context.fillText(
                line,
                width * 0.5,
                height * 0.495 + index * lineHeight
            )
        })
        const boxStart = { x: width * 0.3768, y: height * 0.931 }
        const boxEnd = { x: width * 0.614, y: height * 0.931 }

        context.font = isTamil
            ? `${width * 0.045}px Meera Inimai`
            : `${width * 0.048}px Sree Krushnadevaraya`
        context.fillStyle = 'rgba(247,171,75,255)'
        context.textAlign = 'center'

        const translatedMonth = translateMonth(month, language)
        const combinedText = `${translatedMonth} ${day}`

        const boxWidth = boxEnd.x - boxStart.x
        const centerX = boxStart.x + boxWidth / 2

        context.fillText(combinedText, centerX, boxStart.y)
    }

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        })
    }

    const handleOriginalTextChange = (horoscope, value) => {
        setHoroscopeTexts((prevState) => ({
            ...prevState,
            [horoscope]: value,
        }))
    }

    return (
        <div className="container_main">
            {isOverlayVisible && <LoadingOverlay message={overlayMessage} />}
            <h1 className="horo-title">Daily Horoscope</h1>
            <hr className="line" aria-hidden="true"></hr>
            <HoroscopeDropdowns
                language={language}
                setLanguage={handleLanguageChange}
                month={month}
                setMonth={handleMonthChange}
                day={day}
                setDay={handleDayChange}
                hasData={hasData}
                isSaveDirectlyDisabled={isSaveDirectlyDisabled}
                isGridButtonDisabled={isGridButtonDisabled}
                uploadedDates={uploadedDates}
                onSaveDirectly={handleSaveDirectly}
                zodiacData={zodiacData}
                hasPanchangam={hasPanchangam}
                setHasPanchangam={setHasPanchangam}
            />
            {horoscopes.map((horoscope) => (
                <div
                    className="input-fields"
                    key={horoscope}
                    id={horoscopeIds[horoscope]}
                >
                    <label className="input-title">
                        {getLocalizedHoroscopeName(horoscope)}
                    </label>
                    <TextArea
                        className="text-area"
                        autoSize={{ minRows: 3 }}
                        type="text"
                        value={horoscopeTexts[horoscope] || ''}
                        onChange={(e) =>
                            handleOriginalTextChange(horoscope, e.target.value)
                        }
                    />
                </div>
            ))}
        </div>
    )
}

export default HoroscopeMain
