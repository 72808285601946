import React from 'react'
import './Preview.scss'
import { useLocation } from 'react-router'
import { Carousel } from 'antd'
import ReactPlayer from 'react-player'
import NumberedBulletList from '../../NumberedBulletList/NumberedBulletList'
import MuxPlayer from '@mux/mux-player-react'
const Preview = ({
    image,
    title,
    description,
    questions,
    cardImages,
    videoPath,
    weburl,
    approveContent,
    videoList,
    ad_type,
}) => {
    const location = useLocation()

    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    let token
    let playbackId

    const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
    const temp = youtubeRegex.test(videoPath)
    if (
        videoPath &&
        !temp &&
        videoPath?.includes('token') &&
        videoPath?.includes('.m3u8')
    ) {
        token = videoPath.match(tokenRegex)[1]
        playbackId = videoPath.match(playbackIdRegex)[1]
    }

    const findUrl = (() => {
        if (image === undefined) {
            return undefined
        }
        if (Array.isArray(image)) {
            return image.length > 0 ? image[0] : undefined
        }
        return image
    })()
    const getImageOrCarouselContainer = () => {
        if (
            image &&
            (location.pathname !== '/home/ads' || ad_type === 'shortnews')
        ) {
            return (
                <img
                    style={{ width: '100%', height: '100%' }}
                    alt="news"
                    src={findUrl}
                />
            )
        } else if (cardImages && cardImages?.length > 0) {
            return (
                <Carousel
                    style={{ height: '100%', width: '100%' }}
                    swipeToSlide
                    draggable
                >
                    {cardImages?.map((images, index) => (
                        <img
                            key={index}
                            src={images?.response}
                            alt={`${index}`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    ))}
                </Carousel>
            )
        }
    }

    const getCarouselContainer = () => {
        return (
            <Carousel style={{ height: '100%', width: '100%' }}>
                {cardImages?.length &&
                    cardImages.map((image, index) => {
                        return (
                            <img
                                key={index}
                                src={image}
                                alt={`${index}`}
                                style={{ width: '100%', height: '100%' }}
                            />
                        )
                    })}
                {videoList?.length &&
                    videoList.map((image, index) => {
                        return (
                            <div>
                                {!videoPath && getImageOrCarouselContainer()}
                                {videoPath &&
                                    playbackId !== '' &&
                                    playbackId !== undefined &&
                                    token !== undefined && (
                                        <MuxPlayer
                                            style={{ height: '100%' }}
                                            playbackId={playbackId}
                                            tokens={{ playback: token }}
                                        />
                                    )}
                                {videoPath && temp && (
                                    <ReactPlayer
                                        url={videoPath}
                                        controls
                                        width="100%"
                                        height="100%"
                                    />
                                )}
                            </div>
                        )
                    })}
            </Carousel>
        )
    }

    return (
        <div className="addAd-preview">
            <div className="smartphone">
                {location.pathname !== '/home/html' && (
                    <div className="content">
                        <div
                            className={
                                location?.pathname === '/home/card' ||
                                location?.pathname === '/home/shortVideo' ||
                                location?.pathname ===
                                    '/home/devotionalVideo' ||
                                (location.pathname === '/home/ads' &&
                                    ['html', 'shortvideo', 'card']?.includes(
                                        ad_type
                                    ))
                                    ? 'full-image'
                                    : 'image'
                            }
                        >
                            {!videoPath &&
                                !approveContent &&
                                getImageOrCarouselContainer()}
                            {!approveContent && videoPath && (
                                <ReactPlayer
                                    controls={true}
                                    playing={false}
                                    loop={true}
                                    src={videoPath}
                                    width="100%"
                                    height="100%"
                                    url={videoPath}
                                />
                            )}
                            {['/home/html', '/home/ads']?.includes(
                                location.pathname
                            ) &&
                                weburl && (
                                    <iframe
                                        width={'100%'}
                                        height={'100%'}
                                        src={weburl}
                                    ></iframe>
                                )}
                            {approveContent && getCarouselContainer()}
                        </div>
                        {location?.pathname === '/home/card' ||
                        location?.pathname === '/home/shortVideo' ||
                        location?.pathname === '/home/devotionalVideo' ? (
                            ''
                        ) : (
                            <div>
                                {title &&
                                    (location.pathname !== '/home/ads' ||
                                        ad_type === 'shortnews') && (
                                        <div className="title">
                                            {title?.replace(/\\n/g, '')}
                                        </div>
                                    )}
                                {description &&
                                    (location.pathname !== '/home/ads' ||
                                        ad_type === 'shortnews') && (
                                        <div className="desc">
                                            <NumberedBulletList
                                                text={description}
                                            />
                                        </div>
                                    )}
                                {location?.pathname !== '/home/shortNews' &&
                                    location?.pathname !==
                                        '/home/moveShortNews' &&
                                    location?.pathname !== '/home/shortNews' &&
                                    location?.pathname !== '/home/ug' &&
                                    location?.pathname !== '/home/tv' &&
                                    location?.pathname !== '/home/ads' && (
                                        <div className="questions">
                                            {questions?.question && (
                                                <p>{questions?.question}</p>
                                            )}
                                            {questions?.options &&
                                                questions?.options.map(
                                                    (option, index) => (
                                                        <p
                                                            key={index}
                                                            className="options"
                                                        >
                                                            {option}
                                                        </p>
                                                    )
                                                )}
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                )}
                {location.pathname === '/home/html' && weburl && (
                    <iframe
                        width={'100%'}
                        height={'100%'}
                        src={weburl}
                    ></iframe>
                )}
            </div>
        </div>
    )
}
export default Preview
