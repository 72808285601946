import React from 'react';
import { Tabs } from 'antd';
import EmployeeTable from './EmployeeTable';
import { useSelector } from 'react-redux';
import RuleForDay from './RuleForDay';
import { ExpiryKeywords } from './ExpiryKeywords';
import { Trends } from '../Trends/Trends';
const { TabPane } = Tabs;
const EditorDashboard = () => {
    const location = useSelector((state) => state.app.locations)
    const language = useSelector((state)=>state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
  return (
    <Tabs defaultActiveKey="1" style={{height:"3rem",padding:"0 1px",margin:"0 1rem 0 1rem"}} className='editor_tab'>
      <TabPane tab={<span style={{color:"gray",fontWeight:"bold"}}>Employee</span>} key="1" >
        <EmployeeTable token={accessToken} language={language} locations={location}  />
      </TabPane>
      <TabPane tab={<span style={{fontWeight:"bold",color:"gray"}}>Day Rules</span>} key="2">
       <RuleForDay/>
      </TabPane>
      <TabPane tab={<span style={{fontWeight:"bold",color:"gray"}}>Expiry Keywords</span>} key="3">
       <ExpiryKeywords/>
      </TabPane>
      <TabPane tab={<span style={{fontWeight:"bold",color:"plum",fontFamily:"cursive"}}>Trends</span>} key="4">
      <div style={{display:"flex",width:"100%",height:"70vh",alignItems:"center",justifyContent:"center"}}> <h5>Coming Soon ...</h5></div>
      </TabPane>
    </Tabs>  
  );
};

export default EditorDashboard;
