import { useLocation } from 'react-router'
import './SmartphonePreview.scss'
import { DeleteFilled } from '@ant-design/icons'
const SmartphonePreviewUploadLibrary = ({
    videoPath,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
    handleDeleteImage,
    images,
}) => {
    const location = useLocation()
    const getImage = (url) => {
        return (
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    margin: '6px',
                    boxShadow: '2px 2px 2px lightgray',
                    borderRadius: '5px',
                }}
            >
                <img
                    alt="news"
                    src={url}
                    style={{
                        objectFit: 'cover',
                        height: 100,
                        width: 100,
                        borderRadius: 5,
                        padding: 0,
                    }}
                />
                {!location.pathname.includes('addsnippet-form') && (
                    <DeleteFilled
                        onClick={() => handleDeleteImage(url)}
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            top: 0,
                            right: 0,
                            border: 'none',
                            color: 'red',
                            fontSize: '16px',
                            padding: 4,
                        }}
                    />
                )}
            </div>
        )
    }
    return (
        <div
            style={{ maxWidth:"100%" }}
        >
            {!videoPath && (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {images?.map((e,index) => {
                        return (
                            <div key={index}>
                                {getImage(e)}
                                {/* <div className="content_image_type_">
                    <Checkbox
                        className="check_box_preview_"
                        checked={isOriginal}
                        onChange={(e) => setIsOriginal(e.target.checked)}
                    >
                        Original Image
                    </Checkbox>
                    <div>
                    <Checkbox
                        className="check_box_preview_"
                        checked={isSensitive}
                        onChange={(e) => setIsSensitive(e.target.checked)}
                    >
                        Sensitive Content
                    </Checkbox>
                    </div>
                </div> */}
                            </div>
                        )
                    })}

                    {/* {images?.length > 0 && 
             <div style={{width:120,height:120, margin: '6px',display:"flex",alignItems:"center"}}>
                <ImageUploader          
             act={act}
             modal={true}
             getImagePath={getImagePath}
             setAct={setAct}
             images={images}
             setFiles={setFiles}
             fileFromGoogleSearch={fileFromGoogleSearch}
             fromGoogleSearch={fromGoogleSearch}
         />
             </div>
            } */}
                </div>
            )}
        </div>
    )
}

export default SmartphonePreviewUploadLibrary
