import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Spin, Checkbox, Button, message } from 'antd'
import './imageLibrary.scss'
import { getImages } from '../../AadhanApi'
import Search from 'antd/es/input/Search'
import { CloseSquareFilled } from '@ant-design/icons'
import { useLocation } from 'react-router'

function ImageLibrary({
    setImagePath,
    language_id,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
    searchTerm,
    setSearchTerm,
    setIsImageAdding,
    image,
    videoPath
}) {
    const token = useSelector((state) => state.app.accessToken)
    const [images, setImages] = useState([])
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        if (searchTerm) {
            fetchImages(searchTerm)
        }
    }, [])

    const fetchImages = async () => {
        try {
            setLoading(true)
            const response = await getImages(language_id, searchTerm, token)
            const formattedImages = response.data.data.map((image) => ({
                url: image.image_url,
                keywords: image.keywords || '',
            }))
            setImages(formattedImages)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching images:', error)
        }
    }

    const handleImageSelect = (img) => {
        
        if((videoPath?.stream_url || videoPath?.yt_url || location.pathname.includes('home/reporter-dashboard')) && image?.length>=1){
            message.error('Image limit exceeded. Maximum allowed is 1.');
            return
        }
        else if(image?.length>=5){

            message.error('Image limit exceeded. Maximum allowed is 5.');
            return
        }
        setSelectedImage(img)
        setImagePath(img.url)
        // setIsImageAdding(false)
        message.success('Image added');
    }

    const handleSearch = () => {
        if (searchTerm) {
            fetchImages()
        }
    }
    return (
        <div className="outer_div">
            <div style={{ width: '99.9%', marginLeft: 'auto' }}>
                {' '}
                <Search
                    className="search-bar"
                    placeholder="Search by title or keywords..."
                    enterButton="Search"
                    size="medium"
                    onSearch={handleSearch}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                />
            </div>
            <div className="image-library-container">
                <div
                    className="content-wrapper"
                    style={{
                        flexDirection: selectedImage ? 'row' : 'column',
                    }}
                >
                    <div
                        className="image-library-modal"
                        style={
                            {
                                // flex: selectedImage ? '2' : '1',
                                // height:380,
                                // overflowY:"auto"
                            }
                        }
                    >
                        {!loading && images.length === 0 && (
                            <div className="loading">No Data</div>
                        )}
                        {loading ? (
                            <div className="loading">
                                <Spin size="large"></Spin>
                            </div>
                        ) : (
                            <Row gutter={16}>
                                <Col>
                                    <div className="image-grid">
                                        <Row gutter={[10, 10]}>
                                            {images.map((image, index) => (
                                                <Col
                                                    key={index}
                                                    style={{ margin: 4 }}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleImageSelect(
                                                                image
                                                            )
                                                        }
                                                        className={
                                                            'image-containr'
                                                        }
                                                    >
                                                        <img
                                                            className="grid-image"
                                                            alt={image.keywords}
                                                            src={image.url}
                                                        />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageLibrary
