import React, { useEffect, useState } from 'react'
import './Advertisers.scss'
import { Button, message, Select } from 'antd'
import CommonTable from '../../CommonTable/CommonTable'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import AddAdvertiserModal from './AddAdvertiserModal'
import { getAdvertisers } from '../../../AadhanApi'

function Advertisers() {
    const token = useSelector((state) => state.app.accessToken)
    const [advertiserType, setAdvertiserType] = useState('all')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [advertisersData, setAdvertisersData] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingAdvertiser, setEditingAdvertiser] = useState(null)

    const handleChange = (value) => {
        setAdvertiserType(value)
    }

    const handleAddAdvertiserBtn = () => {
        setEditingAdvertiser(null)
        setIsModalOpen(true)
    }

    const handleEditAdvertiser = (record) => {
        setEditingAdvertiser(record)
        setIsModalOpen(true)
    }

    // const handleDeleteAdvertiser = async (advertiser_id) => {
    //     try {
    //         await deleteAdvertiser(advertiser_id, token)
    //         message.success('Advertiser deleted successfully')
    //         fetchAdvertisers()
    //     } catch (error) {
    //         message.error('Failed to delete advertiser')
    //     }
    // }

    const fetchAdvertisers = async () => {
        try {
            setLoading(true)
            const response = await getAdvertisers(token)
            console.log(response)

            const filteredData =
                advertiserType === 'all'
                    ? response.data.data
                    : response.data.data.filter(
                          (ad) => ad.advertiser_type === advertiserType
                      )
            setAdvertisersData(filteredData)
            setLoading(false)
        } catch (error) {
            message.error('Failed to fetch advertisers')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAdvertisers()
    }, [advertiserType, token])

    const convertUTCtoIST = (utcDateString) => {
        if (!utcDateString) return ''

        // Create a Date object from the UTC string
        const utcDate = new Date(utcDateString)

        // Convert to IST (UTC+5:30)
        const istDate = new Date(
            utcDate.toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata',
            })
        )

        // Format the date to a readable string
        return istDate.toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }

    const columns = [
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '18%',
            render: (text) => convertUTCtoIST(text),
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },
        {
            title: 'Name',
            dataIndex: 'advertiser_name',
            key: 'advertiser_name',
            width: '17%',
        },
        {
            title: 'Contact Person Name',
            dataIndex: 'contact_person_name',
            key: 'contact_person_name',
            width: '18%',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: '13%',
        },
        {
            title: 'Email Id',
            dataIndex: 'email_id',
            key: 'email_id',
            width: '15%',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '23%',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '25%',
            render: (_, record) => (
                <div>
                    <EditOutlined
                        onClick={() => handleEditAdvertiser(record)}
                        style={{ cursor: 'pointer' }}
                    />
                    {/* <Popconfirm
                        title="Delete Advertiser"
                        description="Are you sure to delete this advertiser?"
                        onConfirm={() =>
                            handleDeleteAdvertiser(record.advertiser_id)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm> */}
                </div>
            ),
        },
    ]
    return (
        <div className="advertisers-container">
            <h2>Manage Advertisers</h2>
            <hr></hr>
            <div className="advertisers-div">
                <Select
                    defaultValue="all"
                    style={{ width: '14vw', marginBottom: '10px' }}
                    onChange={handleChange}
                    value={advertiserType}
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'agency', label: 'Agency' },
                        { value: 'direct', label: 'Direct' },
                    ]}
                />
                <Button type="primary" onClick={handleAddAdvertiserBtn}>
                    Add Advertiser
                </Button>
            </div>
            <CommonTable
                columns={columns}
                data={advertisersData}
                loading={loading}
            />
            <div>
                <AddAdvertiserModal
                    isModalOpen={isModalOpen}
                    handleCancel={() => {
                        setIsModalOpen(false)
                        setEditingAdvertiser(null)
                    }}
                    token={token}
                    editingAdvertiser={editingAdvertiser}
                    onSuccess={fetchAdvertisers}
                />
            </div>
        </div>
    )
}

export default Advertisers
