import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { createRole, updateRole } from '../../AadhanApi'
import { useSelector } from 'react-redux'

const AddRole = ({ selectedRole, getTableData, setShowModal, accessToken }) => {
    const [form] = Form.useForm()
    const employeeId = useSelector((state) => state.app.employeeId)
    const onFinish = async (values) => {
        const obj = {
            role_name: values.roleName,
            status: 'active',
            created_by: employeeId,
            created_date: '2023-07-28T17:01:23.731Z',
            updated_by: employeeId,
            updated_date: '2023-07-28T17:01:23.731Z',
        }
        if (selectedRole) {
            await updateRole(obj, accessToken, selectedRole.id)
        } else {
            await createRole(obj, accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                roleName: selectedRole?.role,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRole])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    return (
        <Form
            id="roleForm"
            form={form}
            name="roleForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label="Department Name"
                // name="roleName"
                rules={[
                    { required: true, message: 'Please enter department name' },
                ]}
            >
                <Select></Select>
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="Role Name"
                name="roleName"
                rules={[{ required: true, message: 'Please enter role name' }]}
            >
                <Input />
            </Form.Item>
            <br></br>
        </Form>
    )
}

export default AddRole
