import { DatePicker, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Comments.scss'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import CommonTable from '../CommonTable/CommonTable'
import { getFlaggedComments } from '../../AadhanApi'

function Comments() {
    const { Option } = Select
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[0]?.language_id
    )
    const [selectedType, setSelectedType] = useState('--All--')

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
    }
    const handleTypeChange = (value) => {
        setSelectedType(value)
    }

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await getFlaggedComments(accessToken)
                console.log(response)
            } catch (error) {
                console.error('Error fetching comments:', error)
            }
        }
        fetchComments()
    }, [accessToken])

    const columns = [
        {
            title: 'Date and Time ',
            dataIndex: 'date_and_time',
            key: 'date_and_time',
            width: '25%',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: '25%',
        },

        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: '25%',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '22%',
            render: (_, record) => (
                <div>
                    <Popconfirm
                        title="Approve Comment"
                        description="Are you sure to approve this comment?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <EditOutlined style={{ cursor: 'pointer' }} />
                    </Popconfirm>
                    <Popconfirm
                        title="Delete Comment"
                        description="Are you sure to delete this comment?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                    <Popconfirm
                        title="Delete comment and ban user"
                        description="Are you sure to delete this comment and ban user?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="comments-container">
            <h2 className="comments-title">Manage Comments</h2>
            <hr className="line" aria-hidden="true"></hr>
            <div>
                <Select
                    placeholder="Select a Language"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    className="comments-language-dropdown"
                >
                    {languageData.map((language) => (
                        <Option
                            key={language.language_id}
                            value={language.language_id}
                        >
                            {language.language_name}
                        </Option>
                    ))}
                </Select>
                <DatePicker className="comments-datepicker-dropdown" />
                <Select
                    placeholder="select type"
                    className="comments-type-dropdown"
                    value={selectedType}
                    onChange={handleTypeChange}
                >
                    <Option key="all" value="--All--">
                        --All--
                    </Option>
                    <Option key="abuse" value="abuse">
                        abuse
                    </Option>
                    <Option key="good" value="good">
                        good
                    </Option>
                </Select>
            </div>
            <div>
                <CommonTable
                    columns={columns}
                    // data={fetchComments}
                />
            </div>
        </div>
    )
}

export default Comments
