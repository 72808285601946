import React from 'react'
import { Button, Select } from 'antd'
import { useSelector } from 'react-redux'

const { Option } = Select

function DropdownsForGrid({
    language,
    setLanguage,
    month,
    setMonth,
    day,
    setDay,
}) {
    const languageData = useSelector((state) => state.app.languageData)
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const getDaysInMonth = (month) => {
        const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        return daysInMonth[month] || 31
    }

    return (
        <div className="dropdowns_Grid">
            <Select
                placeholder="Select a Language"
                value={language}
                onChange={setLanguage}
                className="dropdown-horo-1"
            >
                {languageData.map((arr) => (
                    <Option key={arr.language_id} value={arr.language_id}>
                        {arr.language_name}
                    </Option>
                ))}
            </Select>
            <Select
                value={month}
                onChange={setMonth}
                placeholder="Select Month"
                className="dropdown-horo-2"
            >
                {months.map((m, i) => (
                    <Option key={m} value={i}>
                        {m}
                    </Option>
                ))}
            </Select>
            <Select
                value={day}
                onChange={setDay}
                placeholder="Select Day"
                disabled={month === null}
                className="dropdown-horo-3"
            >
                {Array.from(
                    { length: getDaysInMonth(month) },
                    (_, i) => i + 1
                ).map((d) => (
                    <Option key={d} value={d}>
                        {d}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default DropdownsForGrid
