import React, { useState } from 'react';
import { Upload, Button, Spin, message, Card} from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import { getVideoUrl } from '../../../AadhanApi';
const ShortNewsVideoUpload = ({ setVideoPath,accessToken,handleCancel }) => {
    const [isVideoUploading, setIsVideoUploading] = useState(false);
    const beforeUpload = (file) => {
        const isMp4 = file.type === 'video/mp4';
        const isLt50MB = file.size / 1024 / 1024 <= 50;
        const maxDuration = 60; // Maximum duration in seconds
    
        if (!isMp4) {
            message.error('You can only upload MP4 video files!');
            return false;
        }
        if (!isLt50MB) {
            message.error('Video must be smaller than 50MB!');
            return false;
        }
        // Check video duration <= 60s
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;
                if (duration > maxDuration) {
                    message.error(`The video exceeds the allowed duration of ${maxDuration} seconds.`);
                    reject(false);
                } else {
                    resolve(true); 
                }
            };
            video.onerror = () => {
                message.error('Failed to load video metadata.');
                reject(false)
            };
    
            video.src = URL.createObjectURL(file);
        });
    };
    

    const handleVideoUpload = async ({ file, onProgress }) => {
        if (beforeUpload(file)) {
            setIsVideoUploading(true);
            try {
                const pathUri = await getVideoUrl(file, accessToken);

                if (pathUri?.data) {
                    setVideoPath({ stream_url: pathUri?.data, yt_url: "" });
                    handleCancel()
                    message.success('Video uploaded successfully!');

                }
            } catch (error) {
                message.error('Error uploading video!');
                console.error('Error uploading video:', error);
            } finally {
                setIsVideoUploading(false);
            }
        }
    };

    return (
        <Card
            title="Upload Your Video"
            bordered={true}
            style={{ maxWidth: 500, margin: '0 auto', textAlign: 'center' }}
        >
            <Upload
                accept="video/mp4"
                customRequest={handleVideoUpload}
                showUploadList={false}
                beforeUpload={beforeUpload}
            >
                <Button 
                    icon={<UploadOutlined />} 
                    disabled={isVideoUploading} 
                    style={{ marginBottom:16 }}
                >
                    {isVideoUploading ? 'Uploading...' : 'Upload Video'}
                </Button>
            </Upload>

            {isVideoUploading && (
                <div style={{}}>
                    <Spin />
                    {/* <Progress percent={uploadPercent} /> */}
                </div>
                
            )}
        </Card>
    );
};

export default ShortNewsVideoUpload;
