import MuxUploader from "@mux/mux-uploader-react";
import { useState } from "react";
import MuxPlayer from "@mux/mux-player-react";

export default function VideoPlayer() {
  const [playbackId, setPlaybackId] = useState(null);

  const handleUploadComplete = (upload) => {
    console.log("Upload completed:", upload);

    // Ensure the upload object has the expected structure
    if (upload.asset && upload.asset.playback_ids && upload.asset.playback_ids.length > 0) {
      const playbackId = upload.asset.playback_ids[0].id;
      setPlaybackId(playbackId); // Save the playback ID to state
      console.log("Playback ID:", playbackId);
    } else {
      console.error("Upload completed, but no playback ID found:", upload);
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1>Upload and Play Video</h1>

      {/* MuxUploader for video upload */}
      <div style={{ marginBottom: "20px" }}>
        <MuxUploader
          endpoint="https://storage.googleapis.com/video-storage-gcp-us-east4-vop1-uploads/dOjGuEfqZrYkZ5WcrNn1TB?Expires=1733315319&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=Ob%2FoKmgUr9VGe8vTD5XuELjPgB3ErYGFW5lawf8MpUI46MV9zXQLSPZWS5tW%2BbMOVI7d7kfaqsiq4TstvI7qBVtFCil%2FyerzQmKFXZYTYPXYrOgLEZC5NQmjg4%2Fs%2BSpPNa8mo32VLCuToB5%2F7s8Wte9ibO5%2B5M5ilY2I9Uc%2BOsR8gTaaI8CH8NmKKCSm3XuGfX35ZUQ78XmbKz5UVbzRphyNIeZXhNKaL5C8RUuU3Rtn759Ix0LTpIjYFWJDaBG4xjBdziyWXmrF7i70sdPFrT3zBz96RhBoDetR3il30My38LWnpHqc%2BrBE%2BfcDKenuA2K9AbcskFtL8ujRcBycdg%3D%3D&upload_id=AFiumC4UKzPmN3_qsPigRG-AcdaXaqNaybCxms7E3NHDp-ydCFOpARE9bG39fQOFhmHrd9ZlUU004NU9jK7FRBkMbX4-S1DiPYq7xU9YYoU3riTk"
          onc={handleUploadComplete}
        />
      </div>

      {/* MuxPlayer for video playback */}
      {playbackId && (
        <div style={{ marginTop: "20px" }}>
          <h2>Uploaded Video</h2>
          <MuxPlayer
            playbackId={playbackId}
            streamType="on-demand"
            controls
            style={{ width: "100%", height: "400px" }}
          />
        </div>
      )}
    </div>
  );
}
