import React, { useState, useEffect } from 'react'
import {
    Button,
    Select,
    Modal,
    Switch,
    Popconfirm,
    message,
    Upload,
    Form,
    Flex,
} from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import { useSelector } from 'react-redux'
import './ManageVisuals.scss'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import { CloudUploadOutlined, FontSizeOutlined } from '@ant-design/icons'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import Dragger from 'antd/es/upload/Dragger'
import {
    getVisuals,
    createVisuals,
    getVisualsById,
    updateVisuals,
    deleteVisualsById,
    getVisualsCategory,
} from '../../AadhanApi'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { useNavigate, useParams } from 'react-router'
import ViewPageVisuals from './ViewPageVisuals'

const { Option } = Select
const DraggableUploadListItem = ({ originNode, file, fileList }) => {
    const { attributes, listeners, setNodeRef, transition, isDragging } =
        useSortable({
            id: file.uid,
        })
    const style = {
        // transform: CSS.Transform.toString(transform || ''),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {originNode}
        </div>
    )
}
const ManageVisuals = () => {
    const { id, langId } = useParams()
    const [language, setLanguage] = useState(
        langId !== undefined ? parseInt(langId) : 2
    )
    const [title, setTitle] = useState('')
    const [isPopular, setIsPopular] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const [languageOption, setLanguageOption] = useState(1)
    const [filteredData, setFilteredData] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(
        id !== undefined ? id : ''
    )
    const [category, setCategory] = useState(id !== undefined ? id : '')
    const accessToken = useSelector((state) => state.app.accessToken)
    const [loading, setIsLoading] = useState(false)
    const [removedIndex, setRemovedIndex] = useState([])

    const navigate = useNavigate()

    const onDragEnd = ({ active, over }) => {
        // if (active.id !== over?.id) {
        //     setFileList((prevList) => {
        //         const activeIndex = prevList.findIndex(
        //             (i) => i.uid === active.id
        //         )
        //         const overIndex = prevList.findIndex((i) => i.uid === over?.id)
        //         return arrayMove(prevList, activeIndex, overIndex)
        //     })
        // }
    }

    const fetchCategories = async (languageId) => {
        try {
            // setSelectedCategory('')
            const response = await getVisualsCategory(accessToken, languageId)
            const categories = response.data.data.map((category) => ({
                value: category.category_id,
                label: category.category_name,
            }))
            setCategoryOptions([{ value: '', label: 'All' }, ...categories])
        } catch (error) {
            console.error('Failed to fetch categories:', error)
            message.error('Failed to fetch categories')
        }
    }

    const getVisualsData = async (accessToken, languageId) => {
        if (languageId !== undefined && language !== null) {
            try {
                const response = await getVisuals(
                    accessToken,
                    language,
                    category
                )
                const mappedData = response?.data?.data.map((item) => ({
                    id: item.visual_id,
                    title: item.title,
                    category_name: item.visuals_category_name,
                    is_popular: item.is_popular ? 'Yes' : 'No',
                    language_id: item.language_id,
                    images: item.original_images,
                    visuals_category_id: item.visuals_category_id,
                    item_sequence_id: item.item_sequence_id,
                }))
                setData(mappedData)
                setFilteredData(mappedData)
            } catch (err) {
                console.error('Error fetching visuals data:', err)
                message.error('Failed to fetch visuals data')
            }
        }
    }

    useEffect(() => {
        if (accessToken && language !== undefined && language !== null) {
            getVisualsData(accessToken, language)
        }
        setLanguageOption(language)
        let options = JSON.parse(localStorage.getItem('options')) || {}

        // If 'options' is not an object, initialize it as an empty object
        if (typeof options !== 'object' || options === null) {
            options = {}
        }

        // Update the 'page' property
        options.page = 1
        localStorage.setItem('options', JSON.stringify(options))
        getVisualsData(accessToken, language)
    }, [accessToken, language, category])

    useEffect(() => {
        if (accessToken && language) {
            fetchCategories(language)
        }
    }, [accessToken, language])

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    const [isEditing, setIsEditing] = useState(false)
    const [data, setData] = useState([])

    // useEffect(() => {
    //     if (language && selectedCategory) {
    //         const filtered = data.filter(
    //             (item) =>
    //                 item.language_id === language &&
    //                 item.visuals_category_id === selectedCategory
    //         )
    //         setFilteredData(filtered)
    //     } else if (language) {
    //         const filtered = data.filter(
    //             (item) => item.language_id === language
    //         )
    //         setFilteredData(filtered)
    //     } else if (selectedCategory) {
    //         const filtered = data.filter(
    //             (item) => item.visuals_category_id === selectedCategory
    //         )
    //         setFilteredData(filtered)
    //     } else {
    //         setFilteredData(data)
    //     }
    //     setLanguageOption(language)
    // }, [language, selectedCategory, data])

    const showModal = (isEditing = false, visual = null) => {
        setIsEditing(isEditing)
        form.resetFields()
        setFileList([])
        if (isEditing && visual) {
            setTitle(visual.title)
            setFileList(visual.images)
        } else {
            setIsPopular(false)
            setSelectedCategory('')
            setTitle('')
            setLanguageOption(language)
            // fetchCategories(1)
        }

        setIsModalOpen(true)
    }
    const [visualId, setVisualId] = useState('')
    const handleEdit = async (record) => {
        try {
            const response = await getVisualsById(record.id, accessToken)
            const visualDetails = response.data.data

            setRemovedIndex([])
            if (visualDetails) {
                form.setFieldsValue({
                    language: visualDetails.language_id,
                    title: visualDetails.title,
                    category: visualDetails.visuals_category_id,
                    category_name: visualDetails.visuals_category_name,
                    popularCard: visualDetails.is_popular === 'Yes',
                })
                setVisualId(visualDetails?.visual_id)
                setTitle(visualDetails.title)
                setIsPopular(visualDetails.is_popular ? true : false)
                setLanguageOption(visualDetails.language_id)
                setSelectedCategory(visualDetails?.visuals_category_id)
                setFileList(
                    visualDetails.original_images
                        ? visualDetails.original_images.map((url, index) => ({
                              uid: `-${index}`,
                              index: index + 1,
                              name: `image-${index}.jpeg`,
                              status: 'done',
                              url: `${url}?${new Date().getTime()}`,
                              response: `${url}?${new Date().getTime()}`,
                          }))
                        : []
                )
                await fetchCategories(visualDetails.language_id)
                setIsEditing(true)
                setIsModalOpen(true)
            }
        } catch (error) {
            console.error('Error fetching visual details:', error)
            message.error('Failed to fetch visual details')
        }
    }
    const handleOk = async (formValues) => {
        const visualData = {
            ...formValues,
            title: title,
            language: languageOption,
            category: selectedCategory,
            is_popular: formValues.popularCard ? true : false,
            images: fileList.map((file) => file.url),
        }

        form.validateFields().then(async (formValues) => {
            try {
                if (isEditing) {
                    setIsLoading(true)
                    visualData.visual_id = visualId
                    visualData.category =
                        form.getFieldsValue('category').category
                    await updateVisuals(
                        visualData,
                        accessToken,
                        fileList,
                        categoryOptions,
                        removedIndex.join(',')
                    )
                    message.success('Visual updated successfully')
                    setIsLoading(false)
                } else {
                    setIsLoading(true)
                    await createVisuals(
                        visualData,
                        accessToken,
                        fileList,
                        categoryOptions
                    )
                    setIsLoading(false)
                    message.success('New visual created successfully')
                }
                setIsModalOpen(false)
                if (language !== undefined && language !== null) {
                    getVisualsData(accessToken, language)
                }
            } catch (error) {
                console.error('Error saving visual:', error)
                message.error('Failed to save visual')
            }
            setLanguage(languageOption)
            setCategory(selectedCategory)
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        fetchCategories(language)
    }

    const handleChange = ({ fileList: newFileList }) => {
        const updatedFileList = newFileList.map((file) => {
            if (file.status === 'done' && !file.url) {
                return { ...file, url: file.response || file.thumbUrl }
            }
            return file
        })
        setFileList(updatedFileList)
    }

    const deleteRow = async (record) => {
        try {
            await deleteVisualsById(record.id, accessToken)
            const updatedData = data.filter((item) => item.id !== record.id)
            setData(updatedData)
            message.success('Visual deleted successfully')
            getVisualsData(accessToken, language)
        } catch (error) {
            console.error('Error deleting visual:', error)
            message.error('Failed to delete visual')
        }
    }

    const handleCategoryChange = (value) => {
        setSelectedCategory(value)
        setTitle(categoryOptions?.filter((e) => e.value === value)?.[0]?.label)
    }

    const handlePopularChange = (checked) => {
        setIsPopular(checked)
    }
    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
        }
    }

    const handleLanguageChange = (id) => {
        setLanguageOption(id)
        fetchCategories(id)
    }
    const draggerProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            if (
                !file.originFileObj &&
                !removedIndex?.includes(file?.response)
            ) {
                setRemovedIndex([
                    ...removedIndex,
                    file?.response?.replace(/(\?.*)$/, ''),
                ])
            }
            const index = fileList?.indexOf(file)
            const newFileList = fileList?.slice()
            if (index > -1) {
                newFileList?.splice(index, 1)
            }
            setFileList(newFileList)
        },
        // customRequest: customRequest,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                message.error('Image must be smaller than 2MB!')
                return Upload.LIST_IGNORE
            }
            return true
        },
    }
    useEffect(() => {
        form.setFieldValue('category', undefined)
    }, [languageOption])

    const modal = (
        <div>
            <Button
                type="primary"
                onClick={() => showModal()}
                className="add-visual-button"
            >
                Add Visual
            </Button>
            <Modal
                open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                width={950}
                centered
            >
                <div
                    // className="visuals-modal"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div
                        // className="form-column"
                        style={{
                            width: '60%',
                            paddingRight: '20px',
                            paddingLeft: '2px',
                        }}
                    >
                        <h2>{isEditing ? 'Edit Visual' : 'Add Visual'}</h2>
                        <hr></hr>
                        <Form form={form} onFinish={handleOk}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.8vh',
                                }}
                            >
                                <div>
                                    <Form.Item
                                        name="language"
                                        label="Language"
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            onChange={handleLanguageChange}
                                            // className="form-item"
                                            // defaultValue={1}
                                        >
                                            {languageData &&
                                                languageData.map((option) => (
                                                    <Select.Option
                                                        key={option.language_id}
                                                        value={
                                                            option.language_id
                                                        }
                                                    >
                                                        {option.language_name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label={'Category'}
                                        name="category"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select a category',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        className="form-item"
                                        value={selectedCategory}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            onChange={handleCategoryChange}
                                            placeholder="Select Category"
                                            className="form-item"
                                            style={{ width: '100%' }}
                                        >
                                            {categoryOptions.map((cat) => {
                                                if (cat?.label !== 'All') {
                                                    return (
                                                        <Option
                                                            key={cat.value}
                                                            value={cat.value}
                                                        >
                                                            {cat.label}
                                                        </Option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        rules={[
                                            {
                                                required:
                                                    title.length === 0
                                                        ? true
                                                        : false,
                                                message: 'Title required',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <TextArea
                                            value={title}
                                            maxLength={75}
                                            onChange={(e) =>
                                                handleTitleChange(
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label={'Popular Card'}
                                        name="popularCard"
                                        // labelCol={{ span: 10 }}
                                        className="switch-wrapper"
                                        style={{ fontWeight: 550 }}
                                        // wrapperCol={{ span: 25 }}
                                        // labelCol={{ span: 24 }}
                                    >
                                        <Switch
                                            checkedChildren="YES"
                                            unCheckedChildren="NO"
                                            checked={isPopular}
                                            onChange={handlePopularChange}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name="cardImage"
                                        label="Card Image"
                                        rules={[
                                            {
                                                required:
                                                    fileList.length === 0
                                                        ? true
                                                        : false,
                                                message:
                                                    'Please upload your card image',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <DndContext
                                            sensors={[sensor]}
                                            onDragEnd={onDragEnd}
                                        >
                                            <SortableContext
                                                items={fileList.map(
                                                    (f) => f.uid
                                                )}
                                                strategy={
                                                    verticalListSortingStrategy
                                                }
                                            >
                                                <Dragger
                                                    {...draggerProps}
                                                    listType="picture"
                                                    accept="image/jpeg,image/jpg"
                                                    fileList={fileList}
                                                    onChange={handleChange}
                                                    itemRender={(
                                                        originNode,
                                                        file,
                                                        fileList
                                                    ) => (
                                                        <DraggableUploadListItem
                                                            originNode={
                                                                originNode
                                                            }
                                                            file={file}
                                                            fileList={fileList}
                                                        />
                                                    )}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <CloudUploadOutlined />
                                                    </p>
                                                    <Button>
                                                        Select Your Card
                                                    </Button>
                                                    <div
                                                        style={{
                                                            color: '#199be9',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        JPEG, JPG format only
                                                    </div>
                                                </Dragger>
                                            </SortableContext>
                                        </DndContext>
                                    </Form.Item>
                                </div>
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: '5vh',
                                    }}
                                >
                                    <Form.Item>
                                        <Button
                                            disabled={loading}
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                paddingLeft: '25px',
                                                paddingRight: '25px',
                                            }}
                                        >
                                            {isEditing ? 'Update' : 'Save'}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div
                        className="preview-column"
                        style={{
                            marginLeft: '10px',
                            marginRight: '20px',
                        }}
                    >
                        <div className="preview-section">
                            <SmartphonePreview
                                title="hello"
                                cardImages={fileList}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

    const columns = [
        {
            title: 'Visual',
            dataIndex: 'card',
            key: 'card',
            width: '26%',
            render: (_, record) => (
                <img
                    className="visual_card"
                    style={{
                        borderRadius: '2px',
                        border: 'solid 1px white',
                        boxShadow: '2px 2px 2px gray',
                    }}
                    width={70}
                    height={100}
                    src={record?.images?.[0]}
                />
            ),
        },

        {
            title: 'Category Name',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '32%',
        },
        {
            title: 'Popular Card',
            dataIndex: 'is_popular',
            key: 'is_popular',
            width: '21%',
        },
        {
            title: 'Actions',

            key: 'actions',
            align: 'center',
            width: '21%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '1.2rem',
                    }}
                >
                    <EditOutlined onClick={() => handleEdit(record)} />
                    <Popconfirm
                        title="Delete Visual"
                        description="Are you sure to delete this visual?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div>
            {id !== undefined ? (
                <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className="visuals-container"
                >
                    <div
                        style={{
                            // padding: '0 1rem',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '1vh',
                            }}
                        >
                            <svg
                                className="svg-arrow"
                                height="35px"
                                id="Layer_1"
                                style={{
                                    enableBackground: 'new 0 0 512 512',
                                    marginRight: '0px',
                                    marginLeft: '-8px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate('/home/category', {
                                        state: {
                                            selectedLanguage: language,
                                        },
                                    })
                                }}
                                version="1.1"
                                viewBox="0 0 512 512"
                                width="35px"
                                xmlSpace="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                                <polygon
                                    points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
                                    fill="black"
                                />
                            </svg>
                            <h2>Manage Visuals</h2>
                        </div>

                        <div style={{ marginLeft: 'auto', display: 'flex' }}>
                            {/* <Select
                                value={language}
                                onChange={(value) => {
                                    setLanguage(value)
                                    setCategory('')
                                    setSelectedCategory('')
                                    setLanguageOption(value)
                                    form.setFieldsValue({ category: undefined })
                                }}
                                placeholder="Select Language"
                                className="visuals-lang-dropdown"
                            >
                                {languageData?.map((option) => (
                                    <Select.Option
                                        key={option.language_id}
                                        value={option.language_id}
                                    >
                                        {option.language_name}
                                    </Select.Option>
                                ))}
                            </Select> */}
                            {/* <Select
                                onChange={(e) => setCategory(e)}
                                value={category === '' ? 'All' : category}
                                placeholder="Select Category"
                                className="visuals-cat-dropdown-2"
                            >
                                {categoryOptions.map((category) => (
                                    <Select.Option
                                        key={category.value}
                                        value={category.value}
                                    >
                                        {category.label}
                                    </Select.Option>
                                ))}
                            </Select> */}
                        </div>

                        <hr></hr>
                    </div>
                    <hr></hr>
                    <div style={{ display: 'none' }}>{modal}</div>
                    <ViewPageVisuals
                        visuals={filteredData}
                        editVisuals={handleEdit}
                        deleteVisuals={deleteRow}
                    />
                </div>
            ) : (
                <div className="visuals-container">
                    <h2 className="visuals-heading">Manage Visuals</h2>
                    <hr></hr>
                    <div className="visuals-dropdowns">
                        <div className="visuals-controls">
                            <Select
                                value={language}
                                onChange={(value) => {
                                    setLanguage(value)
                                    setSelectedCategory('')
                                    setCategory('')
                                    setLanguageOption(value)
                                    form.setFieldsValue({ category: undefined })
                                }}
                                placeholder="Select Language"
                                className="visuals-lang-dropdown"
                            >
                                {languageData?.map((option) => (
                                    <Select.Option
                                        key={option.language_id}
                                        value={option.language_id}
                                    >
                                        {option.language_name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                onChange={(e) => setCategory(e)}
                                value={category === '' ? 'All' : category}
                                placeholder="Select Category"
                                className="visuals-lang-dropdown-2"
                            >
                                {categoryOptions.map((category) => (
                                    <Select.Option
                                        key={category.value}
                                        value={category.value}
                                    >
                                        {category.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div>{modal}</div>
                    </div>
                    <div>
                        <CommonTable columns={columns} data={filteredData} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ManageVisuals
