import { Button, message, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import CommonTable from '../../CommonTable/CommonTable'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AddEmployeeDepartments from './AddEmployeeDepartments'
import {
    deleteEmployeeDepartments,
    getEmployeeDepartments,
} from '../../../AadhanApi'
import { useSelector } from 'react-redux'

function EmployeeDepartments() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [departmentsData, setDepartmentsData] = useState()
    const [editingDepartments, setEditingDepartments] = useState(null)

    const token = useSelector((state) => state.app.accessToken)

    const handleAddDepartment = () => {
        setIsModalOpen(true)
    }

    const fetchDepartments = async () => {
        try {
            setIsLoading(true)
            const response = await getEmployeeDepartments(token)
            setDepartmentsData(response.data.data)
            setIsLoading(false)
        } catch (error) {
            message.error('Failed to fetch departments data')
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDepartments()
    }, [])

    const handleEditDepartment = (record) => {
        setEditingDepartments(record)
        setIsModalOpen(true)
    }

    const handleDeleteDepartments = async (employee_department_id) => {
        try {
            await deleteEmployeeDepartments(employee_department_id, token)
            message.success('department deleted successfully')
            fetchDepartments()
        } catch (error) {
            message.error(
                error.response?.data?.message || 'Failed to delete department'
            )
        }
    }

    const convertUTCtoIST = (utcDateString) => {
        if (!utcDateString) return ''

        const utcDate = new Date(utcDateString)
        const istDate = new Date(
            utcDate.toLocaleString('en-US', {
                timeZone: 'Asia/Kolkata',
            })
        )

        return istDate.toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
    }

    const columns = [
        {
            title: 'Department',
            dataIndex: 'employee_department_name',
            key: 'employee_department_name',
            width: '23%',
        },

        {
            title: 'Created by',
            dataIndex: 'created_by_name',
            key: 'created_by_name',
            width: '23%',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '23%',
            render: (text) => convertUTCtoIST(text),
        },

        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '23%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '1.2rem',
                    }}
                >
                    <EditOutlined
                        onClick={() => handleEditDepartment(record)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Popconfirm
                        title="Delete Advertiser"
                        description="Are you sure to delete this advertiser?"
                        onConfirm={() =>
                            handleDeleteDepartments(
                                record.employee_department_id
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="roles-container">
            <h2>Manage Departments</h2>
            <hr></hr>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '13px',
                }}
            >
                <Button type="primary" onClick={handleAddDepartment}>
                    Add Department
                </Button>
            </div>
            <CommonTable columns={columns} data={departmentsData} />
            {isModalOpen && (
                <AddEmployeeDepartments
                    isModalOpen={isModalOpen}
                    handleCancel={() => {
                        setIsModalOpen(false)
                    }}
                    editingDepartments={editingDepartments}
                    token={token}
                    onSuccess={fetchDepartments}
                />
            )}
        </div>
    )
}

export default EmployeeDepartments
