import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Slider } from 'antd';
import Cropper from 'react-easy-crop';
import { EditOutlined } from '@ant-design/icons';
import { getImageUrl } from '../../../AadhanApi';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // Needed to avoid cross-origin issues
    image.src = url;
  });

const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Set canvas size to safe area
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Translate canvas context to a central location to allow rotating around the center
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg', 1);
  });
};

const ImageCropper = ({
  setFileList,
  setSelectedImageUrl,
  imageUrl,
  languageOption,
  accessToken,
  fileList,
  index,
  onRadioChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const [initialCrop, setInitialCrop] = useState({ x: 0, y: 0 });
  const [initialZoom, setInitialZoom] = useState(1);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showModal = () => {
    setVisible(true);
    setHasChanges(false); // Reset changes tracker
    setInitialCrop(crop); // Store the initial crop values
    setInitialZoom(zoom); // Store the initial zoom value
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
    if (!hasChanges && (newCrop.x !== initialCrop.x || newCrop.y !== initialCrop.y)) {
      setHasChanges(true);
    }
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
    if (!hasChanges && newZoom !== initialZoom) {
      setHasChanges(true);
    }
  };

  const handleOk = async () => {
    if (!hasChanges) {
      setVisible(false); // No changes, simply close the modal
      return;
    }

    setLoading(true);
    const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
    const pathUri = await getImageUrl(croppedImage, 'shortnews', accessToken, languageOption);
    if (pathUri?.data) {
      const url = pathUri.data;
      const updateFile = fileList.map((e, ind) => (ind === index ? url : e));
      setFileList(updateFile);
      onRadioChange({ target: { name: 'image1' } }, index);
      setSelectedImageUrl([url]);
    }
    setVisible(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <div onClick={showModal}>
        <EditOutlined />
      </div>
      <Modal
        title="Uploaded Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={358}
        okButtonProps={{ disabled: loading }}
        okText="Save"
      >
        <div className="crop-container" style={{ position: 'relative', width: '100%', height: 300 }}>
          <Cropper
            image={imageUrl}
            crop={crop}
            aspectRatio={540 / 525}
            zoom={zoom}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="controls">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={onZoomChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ImageCropper;
