import React, { useState, useEffect } from 'react'
import {
    getReporterConfigs,
    updateReporterConfigs,
    getFaq,
    addFaq,
    editFaq,
    deleteFaq,
} from '../../AadhanApi'
import {
    Switch,
    message,
    Collapse,
    Button,
    Modal,
    Input,
    Select,
    Form,
    Row,
    Col,
} from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    AccountBookFilled,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import './rconfig.scss'

const { Panel } = Collapse

const ReporterConfig = () => {
    const [language, setLanguage] = useState(2)
    const [form] = Form.useForm()
    const [publisherDesignations, setPublisherDesignations] = useState([
        { key: '1', value: '' },
        { key: '2', value: '' },
        { key: '3', value: '' },
        { key: '4', value: '' },
    ])
    const [selectedDesignationKey, setSelectedDesignationKey] = useState('1')

    // State for Q&A Panels
    const [panels, setPanels] = useState([])

    // Redux selectors
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)

    // Fetch Reporter Configurations
    const fetchReporterConfigs = async () => {
        if (!language) {
            message.error('Please select a language')
            return
        }

        try {
            const response = await getReporterConfigs(accessToken, language)
            if (response && response.publisher_designation) {
                const data = response
                const publisherDesignation = response.publisher_designation

                // Populate form fields
                form.setFieldsValue({
                    restricted_timing: data.restricted_timing,
                    max_contents_message: data.max_contents_message,
                    max_videos_limit: data.max_videos_limit,
                    max_contents: data.max_contents,
                    should_show_restricted_timings:
                        data.should_show_restricted_timings,
                    should_show_max_contents_per_hour_limit:
                        data.should_show_max_contents_per_hour_limit,
                    show_mandatory_reporter_update_alert:
                        data.show_mandatory_reporter_update_alert,
                })

                // Populate publisher designations
                if (publisherDesignation.publisher_designation) {
                    const updatedDesignations = publisherDesignations.map(
                        (designation) => ({
                            ...designation,
                            value:
                                publisherDesignation.publisher_designation[
                                    designation.key
                                ] || '',
                        })
                    )
                    setPublisherDesignations(updatedDesignations)
                }
            }
        } catch (error) {
            console.error('Error fetching reporter configs:', error)
            message.error('Failed to fetch configuration data')
        }
    }

    // Fetch Q&A Panels
    const fetchQAPanels = async () => {
        try {
            const fetchedUsers = await getFaq(accessToken, language)

            if (fetchedUsers && Array.isArray(fetchedUsers)) {
                setPanels(fetchedUsers)
            } else {
                setPanels([])
            }
        } catch (error) {
            console.error('Failed to fetch Q&A items:', error)
            //message.error("Failed to load Q&A items.");
        }
    }
    useEffect(() => {
        fetchReporterConfigs()
        fetchQAPanels()
    }, [language])

    // fetchQAPanels()

    // Combined language change handler
    const handleLanguageChange = (value) => {
        setLanguage(value)
        form.setFieldsValue({ language_id: value })

        // Fetch both reporter configs and Q&A panels
    }

    // Publisher Designation Handlers
    const handleDesignationKeyChange = (key) => {
        setSelectedDesignationKey(key)
    }

    const handleDesignationValueChange = (e) => {
        const newValue = e.target.value
        setPublisherDesignations((prevDesignations) =>
            prevDesignations.map((designation) =>
                designation.key === selectedDesignationKey
                    ? { ...designation, value: newValue }
                    : designation
            )
        )
    }

    // Submit Handler for Reporter Configuration
    const handleSubmit = async (values) => {
        try {
            // Convert publisherDesignations to an object for the API
            const publisherDesignationObj = publisherDesignations.reduce(
                (acc, designation) => {
                    acc[designation.key] = designation.value
                    return acc
                },
                {}
            )

            const requestData = {
                restricted_timing: values.restricted_timing,
                max_contents_message: values.max_contents_message,
                max_videos_limit: values.max_videos_limit,
                max_contents: values.max_contents,
                should_show_restricted_timings:
                    values.should_show_restricted_timings,
                should_show_max_contents_per_hour_limit:
                    values.should_show_max_contents_per_hour_limit,
                show_mandatory_reporter_update_alert:
                    values.show_mandatory_reporter_update_alert,
                language_id: language,
                publisher_designation: publisherDesignationObj,
            }

            await updateReporterConfigs(accessToken, requestData)
            message.success('Configuration updated successfully')
        } catch (error) {
            console.error('Error updating config:', error)
            message.error('Failed to update configuration')
        }
    }

    // Q&A Panel Handlers
    const handleAddPanel = () => {
        let newPanelData = {
            question: '',
            answer: '',
            language_id: language,
        }

        Modal.confirm({
            title: 'Add New Q&A',
            content: (
                <div>
                    <Input
                        style={{ padding: '10px' }}
                        placeholder="Question"
                        onChange={(e) =>
                            (newPanelData.question = e.target.value.trim())
                        }
                    />
                    <Input.TextArea
                        placeholder="Answer"
                        rows={4}
                        onChange={(e) =>
                            (newPanelData.answer = e.target.value.trim())
                        }
                    />
                </div>
            ),
            async onOk() {
                if (
                    newPanelData.question &&
                    newPanelData.answer &&
                    newPanelData.language_id
                ) {
                    try {
                        const newUser = await addFaq(accessToken, {
                            language_id: newPanelData.language_id,
                            question: newPanelData.question,
                            answer: newPanelData.answer,
                        })

                        setPanels((prevPanels) => [
                            ...prevPanels,
                            { ...newUser?.new_faq, ...newPanelData },
                        ])
                        message.success('New Q&A added successfully.')
                    } catch (error) {
                        console.error(
                            'Failed to create Q&A:',
                            error.response?.data || error
                        )
                        message.error('Failed to add Q&A.')
                    }
                } else {
                    message.error(
                        'Please fill in both question and answer, and select a language.'
                    )
                    return false
                }
            },
        })
    }

    const handleEditPanel = (panel) => {
        if (!panel.faq_id) {
            message.error('Cannot edit: user_id is missing.')
            console.error('Panel data missing user_id:', panel)

            return
        }

        let editedPanelData = {
            original: { ...panel },
            updated: {
                question: panel.question,
                answer: panel.answer,
                language_id: language,
                faq_id: panel.faq_id, // Ensure user_id is included
            },
        }

        Modal.confirm({
            title: 'Edit Question & Answer',
            content: (
                <div>
                    <Input
                        defaultValue={panel.question}
                        onChange={(e) => {
                            editedPanelData.updated.question =
                                e.target.value.trim()
                        }}
                        placeholder="Enter new question"
                    />
                    <Input.TextArea
                        defaultValue={panel.answer}
                        rows={4}
                        onChange={(e) => {
                            editedPanelData.updated.answer =
                                e.target.value.trim()
                        }}
                        placeholder="Enter new answer"
                    />
                </div>
            ),
            async onOk() {
                if (
                    !editedPanelData.updated.question ||
                    !editedPanelData.updated.answer
                ) {
                    message.error('Please fill in both question and answer.')
                    return false
                }

                try {
                    const updatedUser = await editFaq(
                        accessToken,
                        editedPanelData.updated.language_id,
                        editedPanelData.updated.faq_id,
                        editedPanelData.updated.question,
                        editedPanelData.updated.answer
                    )

                    setPanels((prevPanels) =>
                        prevPanels.map((p) =>
                            p.faq_id === updatedUser.faq_id ? updatedUser : p
                        )
                    )

                    message.success('Q&A updated successfully.')
                } catch (error) {
                    console.error(
                        'Error updating Q&A:',
                        error.response?.message || error
                    )
                    message.error(
                        error.response?.data?.message || 'Failed to update Q&A.'
                    )
                }
            },
        })
    }

    const handleDeletePanel = (panel) => {
        // Log the panel being deleted

        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            async onOk() {
                try {
                    // Call delete API with complete parameters
                    await deleteFaq(panel.faq_id, accessToken, {
                        language_id: panel.language_id || language,
                    })

                    // Explicit state update with logging
                    setPanels((prevPanels) => {
                        const updatedPanels = prevPanels.filter(
                            (p) => p.faq_id !== panel.faq_id
                        )

                        return updatedPanels
                    })

                    message.success('Q&A deleted successfully.')
                } catch (error) {
                    console.error(
                        'Failed to delete Q&A:',
                        error.response?.data || error
                    )
                    message.error('Failed to delete Q&A.')
                }
            },
        })
    }

    const renderPanelHeader = (panel) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <span>{panel.question}</span>
            <div>
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleEditPanel(panel)
                    }}
                    style={{ marginRight: 8 }}
                />
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleDeletePanel(panel)
                    }}
                    danger
                />
            </div>
        </div>
    )

    return (
        <div className="container" style={{width:"100%"}}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <h1 className="headline1">Reporter Configuration</h1>
                <div className="headline">
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        style={{ width: '160px' }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <hr></hr>
            <div>
                <div>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="horizontal"
                    >
                        <Row gutter={16}>
                            {/* Left Column */}
                            <Col span={12}>
                                <Form.Item name="language_id" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <div>
                                    <Form.Item label="Publisher Designation">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Select
                                                value={selectedDesignationKey}
                                                onChange={
                                                    handleDesignationKeyChange
                                                }
                                                style={{
                                                    width: '15%',
                                                    marginLeft: '45px',
                                                }}
                                            >
                                                {publisherDesignations.map(
                                                    (designation) => (
                                                        <Select.Option
                                                            key={
                                                                designation.key
                                                            }
                                                            value={
                                                                designation.key
                                                            }
                                                        >
                                                            {designation.key}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                            <Input
                                                value={
                                                    publisherDesignations.find(
                                                        (designation) =>
                                                            designation.key ===
                                                            selectedDesignationKey
                                                    )?.value || ''
                                                }
                                                onChange={
                                                    handleDesignationValueChange
                                                }
                                                placeholder="Enter designation value"
                                                style={{
                                                    width: '39%',
                                                    marginLeft: '3px',
                                                }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    name="restricted_timing"
                                    label="Restricted Timings"
                                >
                                    <Input
                                        style={{ marginLeft: '65px' }}
                                        placeholder="Restricted Timings"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="max_contents_message"
                                    label="Maximum Contents Message"
                                >
                                    <Input placeholder="Maximum Contents Message" />
                                </Form.Item>
                                <Form.Item
                                    name="max_videos_limit"
                                    label="Maximum Videos Limit"
                                >
                                    <Input
                                        style={{ marginLeft: '40px' }}
                                        placeholder="Maximum videos Limit"
                                    />
                                </Form.Item>
                            </Col>

                            {/* Right Column */}
                            <Col span={12} style={{ marginTop: '35px' }}>
                                <Form.Item
                                    name="max_contents"
                                    label="Maximum Content"
                                >
                                    <Input
                                        style={{ marginLeft: '85px' }}
                                        placeholder="Maximum Content"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="show_mandatory_reporter_update_alert"
                                    label="Show Mandatory Reporter Update Alert"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>

                                <Form.Item
                                    name="should_show_restricted_timings"
                                    label="Should Show Restricted Timings"
                                    valuePropName="checked"
                                >
                                    <Switch style={{ marginLeft: '10px' }} />
                                </Form.Item>

                                <Form.Item
                                    name="should_show_max_contents_per_hour_limit"
                                    label="Should Show Maximum Contents per Hour Limit"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Row>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                width: '160px',
                                                marginTop: '15px',
                                                marginRight: '168px', // Pushes the button to the left
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </div>
                <h1 className="reporter">Reporter Panel</h1>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        type="primary"
                        onClick={handleAddPanel}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '145px',
                        }}
                    >
                        Add Question
                    </Button>
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    marginTop:".5rem",
                    display: 'flex',
                    justifyContent: 'Start',
                }}
            >
                <div style={{width:"100%"}}>
                    <Collapse >
                        {panels.map((panel) => (
                            <Panel
                                key={panel.faq_id}
                                header={renderPanelHeader(panel)}
                            >
                                {panel.answer}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

export default ReporterConfig
