import React, { useRef, useState, useEffect } from 'react'
import { Button, message, Modal, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import PropTypes from 'prop-types'
import HoroscopeCanvas from './HoroscopeCanvas'
import './HoroscopeMain.scss'
import {
    updateHoroscopeCard,
    createHoroscopeCard,
    uploadImageToTest,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const HoroscopeModal = ({
    isModalOpen,
    setIsModalOpen,
    currentHoroscope,
    modalText,
    setModalText,
    month,
    day,
    onImageExport,
    language,
    horoscopeTexts,
    horoscopeUrls,
    horoscopeIds,
    onModalUpdate,
    fetchHoroscopes,
    horoscopeCodes,
    currentHoroId,
    fullDescText,
    cardDate,
    uploadedDate,
    zodiacData,
}) => {
    const canvasRef = useRef(null)
    const [drawCanvas, setDrawCanvas] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const date = dayjs(cardDate)
    const monthName = date.format('MMMM')
    const dayName = date.format('DD')
    const accessToken = useSelector((state) => state.app.accessToken)

    useEffect(() => {
        const loadCanvasData = async () => {
            try {
                if (drawCanvas) {
                    drawCanvas(295.2, 640, 1, true)
                }
            } catch (error) {
                console.error('Error loading canvas data:', error)
                message.error(
                    'Failed to load horoscope image. Please try again.'
                )
            }
        }

        loadCanvasData()
    }, [drawCanvas])

    const handleUpdate = async () => {
        setIsModalOpen(false)
        setIsLoading(true)

        try {
            const displayWidth = 196.8
            const displayHeight = 426.66666666667
            const exportWidth = 236.16
            const exportHeight = 512

            const widthScaleFactor = exportWidth / displayWidth
            const heightScaleFactor = exportHeight / displayHeight
            const scaleFactor = (widthScaleFactor + heightScaleFactor) / 2

            if (typeof drawCanvas === 'function') {
                drawCanvas(exportWidth, exportHeight, scaleFactor, true, true)
            } else {
                console.error('drawCanvas is not a function')
                throw new Error('drawCanvas is not a function')
            }
            const imageBlob = await new Promise((resolve) => {
                if (canvasRef.current) {
                    canvasRef.current.toBlob(
                        (blob) => resolve(blob),
                        'image/jpeg',
                        1.0
                    )
                } else {
                    resolve(null)
                }
            })

            if (!imageBlob) {
                throw new Error('Failed to create blob from canvas')
            }

            const imageFile = new File(
                [imageBlob],
                `${currentHoroscope}.jpeg`,
                { type: 'image/jpeg' }
            )
            const imageUploadResponse = await uploadImageToTest(
                imageFile,
                accessToken
            )

            const imageUrl = imageUploadResponse.data
            setImageUrl(imageUrl)

            let date

            if (typeof month === 'string' && !isNaN(month)) {
                month = parseInt(month, 10)
            }

            if (typeof month === 'number') {
                date = new Date(2024, month, day)
            } else if (typeof month === 'string') {
                const monthNames = [
                    'january',
                    'february',
                    'march',
                    'april',
                    'may',
                    'june',
                    'july',
                    'august',
                    'september',
                    'october',
                    'november',
                    'december',
                ]
                const monthIndex = monthNames.indexOf(month.toLowerCase())
                if (monthIndex === -1) {
                    throw new Error('Invalid month name')
                }
                date = new Date(2024, monthIndex, day)
            } else {
                throw new Error('Invalid month format')
            }
            date.setHours(0, 0, 0, 0)
            const isoString = `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, '0')}-${String(date.getDate()).padStart(
                2,
                '0'
            )}T${String(date.getHours()).padStart(2, '0')}:${String(
                date.getMinutes()
            ).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`

            const payload = {
                code: currentHoroId || '',
                language_id: language,
                status: 'complete',
                date: isoString,
                uploaded_date: uploadedDate,
                zodiac_sign: {
                    name: currentHoroscope,
                    full_desc:
                        fullDescText || horoscopeTexts[currentHoroscope] || '',
                    short_desc: modalText,
                    url: imageUrl,
                },
            }

            let response
            if (currentHoroId) {
                response = await updateHoroscopeCard(currentHoroId, payload)
            } else {
                response = await createHoroscopeCard(payload)
            }

            if (response.data.success) {
                if (onImageExport && typeof onImageExport === 'function') {
                    onImageExport(imageUrl)
                }
                if (onModalUpdate && typeof onModalUpdate === 'function') {
                    onModalUpdate(currentHoroscope, modalText)
                }
                fetchHoroscopes()
                setIsModalOpen(false)
            } else {
                console.error('API returned success: false', response.data)
                alert('Failed to update horoscope. Please try again.')
            }
            drawCanvas(displayWidth, displayHeight, 1, true, false)
        } catch (error) {
            console.error('Error in handleUpdate:', error)
            console.error('Full error object:', JSON.stringify(error, null, 2))
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <>
            {isLoading ? (
                <div>
                    <Spin fullscreen />
                </div>
            ) : (
                <Modal
                    okText="Update"
                    open={isModalOpen}
                    onCancel={() => {
                        setIsModalOpen(false)
                        setImageUrl(null)
                    }}
                    onOk={handleUpdate}
                    width={630}
                    centered
                    footer={null}
                >
                    <div>
                        <h3 className="modal-title">
                            {currentHoroscope} Horoscope Modal
                        </h3>
                        <hr className="hr-line"></hr>
                        <div className="modal-content-info">
                            <div className="form-column">
                                <label className="label-headings">
                                    Summarized Text
                                </label>
                                <TextArea
                                    autoSize={{ minRows: 12 }}
                                    showCount
                                    className="modal-text-area"
                                    value={modalText}
                                    onChange={(e) => {
                                        setModalText(e.target.value)
                                    }}
                                />
                                <div className="cancelAndUpdateBtn">
                                    <Button
                                        className="updateBtn"
                                        type="primary"
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                            <div className="canvas-column">
                                <HoroscopeCanvas
                                    modalText={modalText}
                                    month={monthName}
                                    day={dayName}
                                    currentHoroscope={currentHoroscope}
                                    onImageExport={onImageExport}
                                    setDrawCanvas={setDrawCanvas}
                                    ref={canvasRef}
                                    language={language}
                                    localizedHoroscopeName={currentHoroscope}
                                    zodiacData={zodiacData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

HoroscopeModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    currentHoroscope: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired,
    setModalText: PropTypes.func.isRequired,
    month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onImageExport: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    horoscopeTexts: PropTypes.object.isRequired,
    horoscopeUrls: PropTypes.object.isRequired,
    horoscopeIds: PropTypes.object.isRequired,
    drawCanvas: PropTypes.func.isRequired,
    onModalUpdate: PropTypes.func.isRequired,
    localizedHoroscopeName: PropTypes.string.isRequired,
}

export default HoroscopeModal
